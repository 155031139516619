<ngb-carousel
  [interval]="4000"
  [pauseOnHover]="true"
  [showNavigationArrows]="false"
>
  <ng-template *ngFor="let holiday of holidays" ngbSlide>
    <div class="wrapper">
      <div class="card bg-dark text-white">
        <img [src]="holiday.imagen.url" #banner alt="Banner bienvenida" />

        <div *ngIf="holiday.url != ''" class="card-img-overlay mt-3">
          <a class="white-font" href="{{ holiday.url }}" target="_blank">
            <h1>{{ holiday.title }}</h1>
            <p class="card-text">{{ holiday.description }}</p>
          </a>
        </div>
        <div *ngIf="holiday.pdf != ''" class="card-img-overlay mt-3">
          <a
            class="white-font"
            href="javascript:void();"
            (click)="getHolidayPdf(holiday.id, holiday.title)"
          >
            <h1>{{ holiday.title }}</h1>
            <p class="card-text">{{ holiday.description }}</p>
          </a>
        </div>
        <div *ngIf="holiday.tag != ''" class="card-img-overlay mt-3">
          <a class="white-font" routerLink="/catalog/{{ holiday.id }}">
            <h1>{{ holiday.title }}</h1>
            <p class="card-text">{{ holiday.description }}</p>
          </a>
        </div>
        <div
          *ngIf="holiday.tag == '' && holiday.pdf == '' && holiday.url == ''"
          class="card-img-overlay mt-3"
        >
          <a class="white-font">
            <h1>{{ holiday.title }}</h1>
            <p class="card-text">{{ holiday.description }}</p>
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>
