import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { RouterModule } from '@angular/router';
import {
  NgbCarouselModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SliderCardsComponent } from './components/slider-cards/slider-cards.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ImageFormComponent } from './components/image-form/image-form.component';
import { ModalComponent } from './components/modal/modal.component';
import { ImageSelectComponent } from './components/image-select/image-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListLocationsComponent } from './components/list-locations/list-locations.component';
import { LocationFilterPipe } from './pipes/location-filter.pipe';
import { ToastComponent } from './components/toast/toast.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { TycComponent } from './components/tyc/tyc.component';
import { ReportAbuseComponent } from './components/report-abuse/report-abuse.component';
import { ReCaptchaModule } from 'angular-recaptcha3';
import * as ConfigJson from 'src/assets/json/config.json';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HelpButtonComponent } from './components/help-button/help-button.component';
import { TruncateWordsPipe } from './pipes/truncate-words.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    ImageGalleryComponent,
    SliderCardsComponent,
    BreadcrumbComponent,
    ImageFormComponent,
    ModalComponent,
    ImageSelectComponent,
    ListLocationsComponent,
    LocationFilterPipe,
    TruncatePipe,
    TruncateWordsPipe,
    ToastComponent,
    TycComponent,
    ReportAbuseComponent,
    HelpButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbCarouselModule,
    NgbPaginationModule,
    FormsModule,
    ImageCropperModule,
    ReactiveFormsModule,
    ReCaptchaModule.forRoot(ConfigJson.RECAPTCHA_OPTION)
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    ImageGalleryComponent,
    SliderCardsComponent,
    BreadcrumbComponent,
    ImageSelectComponent,
    ListLocationsComponent,
    LocationFilterPipe,
    TruncatePipe,
    TruncateWordsPipe,
    ToastComponent,
    TycComponent,
    ReportAbuseComponent,
    HelpButtonComponent
  ]
})
export class SharedModule { }
