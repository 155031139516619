import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IHoliday } from 'src/app/core/models/holiday';
import { IPublishment } from 'src/app/core/models/publishment';
import { CatalogService } from 'src/app/core/services/http/catalog.service';
import { DownloadService } from 'src/app/core/services/http/download.service';
import { HolidayService } from 'src/app/core/services/http/holiday.service';
import { PublishmentService } from 'src/app/core/services/http/publishment.service';


@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {

  breadcrumbItems: { path: string; url: string }[] = [];

  holiday: IHoliday;
  publishments: IPublishment[];
  params: any;
  currentPage: string = '1';
  perPage: number;

  pagination: any = {
    resultsNumber: null,
    currentPage: '1',
    perPage: null,
    total: null
  }

  constructor(
    private _publishmentService: PublishmentService,
    private _holidayService: HolidayService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _catalogService: CatalogService,
    private _downloadService: DownloadService
  ) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params) => {
      this.params = params;
      this.getHoliday();
      this.getPublishments(this.pagination.currentPage);
    });
  }

  getPublishments(currentPage: string) {
    this._publishmentService.getByHolidayTag(this.params.holidayId, currentPage).subscribe((res: any) => {
      this.publishments = res.data;
      this.pagination.currentPage = res.current_page;
      this.pagination.perPage = res.per_page
      this.pagination.total = res.total
    });
  }

  getHoliday() {
    this._holidayService.getById(this.params.holidayId).subscribe((res) => {
      this.holiday = res.data;
      this.breadcrumbItems = [
        { path: 'inicio', url: '/' },
        { path: 'campañas', url: '/catalog' },
        { path: res.data.title, url: null },
      ];
    });
  }

  showPublishmentDetail(publishment: IPublishment): string {
    return `/publishment/${publishment.id}`;
  }

  async getByHolidayTag() {
    const url = this._catalogService.getByHolidayTagUrl(this.params.holidayId);
    const file = await this._downloadService.getDownloadFile(url).toPromise();
    if (file) {
      this._downloadService.downloadFile(file, "application/pdf", 'Catálogo del rubro ' + this.holiday.title + '.pdf');
    }
  }

}
