import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IPublishment } from 'src/app/core/models/publishment';

@Component({
  selector: 'app-testimonios',
  templateUrl: './testimonios.component.html',
  styleUrls: ['./testimonios.component.scss'],
})
export class TestimoniosComponent implements OnInit {
  currentPage: number;
  perPage: number;
  location: string;
  query: string;
  @ViewChild('tabParent') Element: ElementRef;

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
