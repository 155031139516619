import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IPublishment } from 'src/app/core/models/publishment';
import { CatalogService } from 'src/app/core/services/http/catalog.service';
import { DownloadService } from 'src/app/core/services/http/download.service';
import { NumberLiteralType } from 'typescript';
import { SearchFormComponent } from './search-form/search-form.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  typePublishment_id: number;
  rubric: string;
  resultsNumber: number;
  currentPage: number;
  perPage: number;
  location: string;
  query: string;
  results: IPublishment[];
  filteredResults: IPublishment[];
  showMsjSinResults: boolean = false;
  orderSelected: any;
  resultsNumberFiltered: number;
  currentValue: number = 0;
  showFiltered: boolean = false;
  categoriaId: number = 0; // Almacenar el valor localmente
  @ViewChild('searchForm') searchForm: SearchFormComponent;
  @ViewChild('tabParent') Element: ElementRef;

  labels: any = {
    previousLabel: '',
    nextLabel: '',
  };

  constructor(
    private _catalogService: CatalogService,
    private _downloadService: DownloadService
  ) {}

  ngOnInit(): void {}

  // Método que será llamado cuando ocurra el evento (categoriaIdChange)
  updateCategoriaId(newCategoriaId: number): void {
    this.categoriaId = newCategoriaId;
    console.log('Categoría actualizada en SearchComponent:', this.categoriaId);

    if (this.categoriaId === 0) {
      this.showFiltered = false;
      // this.currentPage = results['resultados']['current_page'];
    } else {
      this.filteredResults = this.results.filter(
        (result: any) => result.categoria_id === this.categoriaId
      );
      this.resultsNumberFiltered = this.filteredResults.length;
      this.showFiltered = true;
    }
  }

  getResults(results: IPublishment[]) {
    if (results['resultados']['data'].length > 0) {
      this.showMsjSinResults = false;

      this.results = results['resultados']['data'];
      this.currentPage = results['resultados']['current_page'];
      this.resultsNumber = results['resultados']['total'];
      this.perPage = results['resultados']['per_page'];
      this.orderSelected = results['query']['orden']
        ? results['query']['orden']
        : 0;
      this.query =
        results['query']['string'] !== '' ? results['query']['string'] : null;
      this.location =
        results['query']['barrio'] !== '' ? results['query']['barrio'] : null;
      this.rubric =
        results['query']['rubro'] !== '' ? results['query']['rubro'] : null;
      this.typePublishment_id = results['query']['tipoPublicacionId'];
    } else {
      this.showMsjSinResults = true;
      this.results = null;
      this.resultsNumber = null;
      this.query = null;
      this.location = null;
      this.rubric = null;
    }
  }

  showPublishmentDetail(publishment: IPublishment): string {
    if (
      publishment.tipoPublicacion.id ===
      this.searchForm.enumPublishmentTypes.Perfil
    ) {
      return `/publishment/profile/${publishment.id}`;
    } else {
      return `/publishment/${publishment.id}`;
    }
  }

  showMore(page: number) {
    let tab = '#tab_' + (this.results.length - 1);
    setTimeout(() => {
      this.Element.nativeElement.querySelector(tab).focus();
    }, 0);
    this.searchForm.currentPage = page;
    this.searchForm.onSubmit();
  }

  order() {
    this.searchForm.currentPage = 0;
    this.searchForm.orderSelected = this.orderSelected;
    this.searchForm.onSubmit();
  }

  async getCatalogDownload() {
    const searchParams = this.searchForm.searchForm.value;
    const url = this._catalogService.getByRubricSearchUrl(
      searchParams.type,
      searchParams.rubric,
      this.searchForm.locacionSelected,
      searchParams.query
    );

    const file = await this._downloadService.getDownloadFile(url).toPromise();
    if (file) {
      this._downloadService.downloadFile(
        file,
        'application/pdf',
        'Catálogo del rubro ' + this.rubric + '.pdf'
      );
    }
  }
}
