<app-breadcrumb></app-breadcrumb>
<div class="container justify-content-center pb-5 pt-5" role="main">
  <h1 class="text-center">¿Qué estás buscando?</h1>
  <p class="lead text-center">
    Ingresá el producto o servicio que necesitas, o el nombre de un
    emprendimiento específico para ver los resultados. Asegúrate de seleccionar
    una categoría de búsqueda de la lista antes de presionar
    <span class="bold">buscar</span>.
  </p>

  <app-search-form #searchForm (results)="getResults($event)">
  </app-search-form>

  <div
    class="justify-content-center mb-2 pt-5"
    *ngIf="results?.length > 0"
    role="region"
    aria-labelledby="search-results_label categoria-result_label cantidad-result_label"
  >
    <div class="row pb-4 mt-3">
      <div class="col-8">
        <p class="visuallyhidden" id="search-results_label">
          Resultados de busqueda
        </p>
        <h3 class="category-title" id="categoria-result_label">
          {{
            rubric
              ? rubric.length > 40
                ? rubric.slice(0, 40) + "..."
                : rubric
              : ""
          }}
          {{ rubric && query ? " - " : "" }} {{ query ? query : "" }}
        </h3>
        <span id="cantidad-result_label">
          - {{ resultsNumber }} resultados
          {{ location ? " en " + location : "" }}
          <a
            (click)="getCatalogDownload()"
            *ngIf="searchForm.searchForm.controls.rubric.value !== ''"
            href="javascript:void(0);"
          >
            <ng-template #tipsCatalogo>Descargar<br />Catálogo</ng-template>
            <img
              style="max-width: 18px"
              tooltipClass="custom-tooltip-white"
              placement="right"
              [ngbTooltip]="tipsCatalogo"
              src="../../../assets/images/download.svg"
            />
          </a>
        </span>
      </div>

      <div class="col-4 sort-by">
        <select
          class="form-control"
          [(ngModel)]="orderSelected"
          (change)="order()"
          aria-label="Ordenar resultados por"
        >
          <option value="" disabled>Ordenar por</option>
          <option value="0">Fecha</option>
          <option value="1">Título</option>
        </select>
      </div>
    </div>

    <div class="row" #tabParent>
      <div
        *ngFor="
          let result of results
            | paginate
              : {
                  id: 'searchPagination',
                  itemsPerPage: perPage,
                  currentPage: currentPage,
                  totalItems: resultsNumber
                };
          let i = index
        "
        class="container-card col-md-6 col-lg-4"
      >
        <div class="card" tabindex="0" id="tab_{{ i }}">
          <img
            [src]="result.imagenPortada?.url"
            onerror="this.src='assets/images/card-image-overlay.jpg'"
            class="card-img-top"
            [alt]="result.titulo"
          />
          <div class="card-body">
            <p class="card-headline">
              {{
                result.tipoPublicacion.nombre == "Perfil"
                  ? "Emprendimiento"
                  : result.tipoPublicacion.nombre
              }}
            </p>
            <h3 class="card-title">
              <a
                [routerLink]="showPublishmentDetail(result)"
                class="card-title-link"
                >{{ result.titulo }}</a
              >
            </h3>
            <p class="card-text">{{ result.descripcion | truncateWords:15 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-right">
      <pagination-controls
        id="searchPagination"
        class="pagination-custom-style"
        [nextLabel]="labels.nextLabel"
        [previousLabel]="labels.previousLabel"
        (pageChange)="showMore($event)"
      ></pagination-controls>
    </div>
  </div>

  <div class="text-center mt-4" *ngIf="showMsjSinResults">
    <h2>No se encontraron resultados para los filtros seleccionados</h2>
  </div>
</div>
<app-help-button></app-help-button>
