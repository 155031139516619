import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComprarComponent } from './quiero-comprar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ComprarComponent],
  imports: [CommonModule, SharedModule, NgbTooltipModule],
  exports: [ComprarComponent],
})
export class ComprarModule { }
