<app-breadcrumb></app-breadcrumb>
<div class="container justify-content-center" role="main">
  <h1 class="text-center">Quiero comprar</h1>
  <div class="panel-horizontal-content">
    <div class="card card-simple panel-horizontal">
      <div class="yt-video col-md-7"></div>
      <div class="card-body">
        <div class="panel-footer">
          <a class="btn btn-primary btn-lg" href="/#buscador-home">Generá compras con impacto</a>
          <a class="btn btn-secondary btn-lg"
            href="https://buenosaires.gob.ar/desarrollohumanoyhabitat/potencial-humano/sello-de-impacto-social"
            target="_blank">Obtené el Sello con impacto</a>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex flex-column align-items-center">
    <div class="pb-5">
      <h1 class="pb-3">Tips para comprar en valorBA</h1>
      <ul class="list-steps list-steps-sm">
        <li class="list-steps-item">
          <h3>Explorá el Catálogo de proveedores de impacto</h3>
        </li>
        <li class="list-steps-item">
          <h3>Elegí el producto que necesites</h3>
        </li>
        <li class="list-steps-item">
          <h3>
            Contactate con el proveedor o solicitá asesoramiento del equipo
          </h3>
        </li>
        <li class="list-steps-item">
          <h3>Realizá tu pedido</h3>
        </li>
      </ul>
    </div>
    <a [href]="obtenerUrlWhatsapp()" target="_blank" rel="noopener noreferrer"
      class="btn btn-primary btn-lg btn-asesoramiento mb-5">
      Quiero asesoramiento
    </a>
  </div>
</div>
<app-help-button></app-help-button>