<div class="carousel">
  <ngb-carousel *ngIf="imgs.length > 0">
    <ng-template *ngFor="let img of imgs" ngbSlide>
      <!-- <div
        class="modal modal-carousel d-block"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        id="modalGallery"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <a href="#" class="modal-carousel-close" data-dismiss="modal">
              Cerrar ventana
            </a>
            <div
              id="modalGalleryControls"
              class="carousel"
              data-ride="carousel"
              data-interval="false"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    [src]="img.src"
                    class="d-block w-100"
                    alt="Texto alternativo de la imagen"
                  />
                  <div class="carousel-caption">
                    <div class="row mb-4">
                      <div class="col">
                        <span class="text-xs">Autor de la imagen.</span>
                      </div>
                      <div class="col text-right">
                        <span class="text-xs">Imagen 1/3</span>
                      </div>
                    </div>
                    <h5 class="h2">Título de la imagen 1.</h5>
                    <p>
                      Descripción o epígrafe de la imagen.
                      <br />
                      Recomendamos en este espacio no utilizar más de 2 líneas,
                      para generar una lectura óptima.
                    </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    src="gallery/2.jpg"
                    class="d-block w-100"
                    alt="Texto alternativo de la imagen"
                  />
                  <div class="carousel-caption">
                    <div class="row mb-4">
                      <div class="col">
                        <span class="text-xs">Autor de la imagen.</span>
                      </div>
                      <div class="col text-right">
                        <span class="text-xs">Imagen 2/3</span>
                      </div>
                    </div>
                    <h5 class="h2">Título de la imagen 2.</h5>
                    <p>
                      Descripción o epígrafe de la imagen.
                      <br />
                      Recomendamos en este espacio no utilizar más de 2 líneas,
                      para generar una lectura óptima.
                    </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    src="gallery/3.jpg"
                    class="d-block w-100"
                    alt="Texto alternativo de la imagen"
                  />
                  <div class="carousel-caption">
                    <div class="row mb-4">
                      <div class="col">
                        <span class="text-xs">Autor de la imagen.</span>
                      </div>
                      <div class="col text-right">
                        <span class="text-xs">Imagen 3/3</span>
                      </div>
                    </div>
                    <h5 class="h2">Título de la imagen 3.</h5>
                    <p>
                      Descripción o epígrafe de la imagen.
                      <br />
                      Recomendamos en este espacio no utilizar más de 2 líneas,
                      para generar una lectura óptima.
                    </p>
                  </div>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-target="#modalGalleryControls"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Anterior</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-target="#modalGalleryControls"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <div class="picsum-img-wrapper">
        <i
          class="glyphicon glyphicon-trash"
          *ngIf="editable"
          (click)="deleteImg(img)"
        ></i>
        <img [src]="img.src" alt="slide" />
      </div>
      <div class="carousel-caption">
        <p class="carousel-title mb-2">{{ img.title }}</p>
        <p>{{ img.desc }}</p>
      </div>
    </ng-template>
  </ngb-carousel>
  <div *ngIf="imgs.length === 0">No hay imágenes</div>
</div>
