import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/config/app-config.service';

@Component({
  selector: 'app-vender',
  templateUrl: './quiero-vender.component.html',
  styleUrls: ['./quiero-vender.component.scss'],
})
export class VenderComponent {
  protected settings = AppConfigService.settings;
  constructor(private router: Router) { }

  obtenerUrlWhatsapp() {
    const mensaje: string =
      '¡Hola! estoy interesado en recibir asesoramiento personalizado sobre cómo subir mi perfil y mostrar de manera efectiva mis productos o servicios en el catálogo de ValorBA.';

    const urlEncodedMessage = encodeURIComponent(mensaje);
    return `https://wa.me/${this.settings.whatsapp}?text=${urlEncodedMessage}`;
  }

  goToMiBA() {
    window.location.href =
      this.settings.url_server + '/auth/redirect?url=' + window.location.href;
  }
}
