<app-breadcrumb></app-breadcrumb>

<div class="container" id="rondas">

  <div class="panel-horizontal-content">
    <h1 class="mx-3">Rondas de vinculación comercial</h1>
    <div class="card card-simple panel-horizontal">
      <iframe class="card-img" src="https://www.youtube.com/embed/sXE613Oaxvc?si=iWSX1erqQxXOLojw"
        title="Buenos Aires se escribe en plural" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen=""></iframe>
      <div class="card-body">
        <h2 class="card-title">Conocé la experiencia de (nombre empresa) con (nombre UP)
        </h2>
        <p class="card-text">
          Las rondas de vinculación comercial son encuentros con proveedores de impacto social, económico, ambiental y
          de
          género para incorporarlas al sistema de proveedores o a tu cadena de valor.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container" id="ferias">

  <div class="panel-horizontal-content">
    <h1 class="mx-3">Ferias</h1>
    <div class="card card-simple panel-horizontal">
      <iframe class="card-img" src="https://www.youtube.com/embed/sXE613Oaxvc?si=iWSX1erqQxXOLojw"
        title="Buenos Aires se escribe en plural" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen=""></iframe>
      <div class="card-body">
        <h2 class="card-title">Conocé la experiencia de (nombre UP) en la feria (nombre de la feria)
          de la Ciudad.
        </h2>
        <p class="card-text">
          Las ferias de la Ciudad conectan al consumidor final con el proveedor
          de la de impacto.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container mb-5" id="casos-de-exito">
  <div class="panel-horizontal-content">
    <h1 class="mx-3">Casos de éxito</h1>
    <div class="container">
      <div class="card-deck max-cards-2">
        <div class="card card-simple panel-vertical">
          <iframe class="card-img" src="https://www.youtube.com/embed/sXE613Oaxvc?si=iWSX1erqQxXOLojw"
            title="Buenos Aires se escribe en plural" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen=""></iframe>
          <div class="card-body">
            <h2 class="card-title">Título del destacado</h2>
            <p class="card-text">
              Este es el cuerpo de un destacado. Debe ser breve y conciso, de pocas
              líneas. No puede contener negritas ni enlaces. Este es el cuerpo de un
              destacado. Debe ser breve y conciso, de pocas líneas. No puede
              contener negritas ni enlaces
            </p>

          </div>
        </div>
        <div class="card card-simple panel-vertical">
          <iframe class="card-img" src="https://www.youtube.com/embed/sXE613Oaxvc?si=iWSX1erqQxXOLojw"
            title="Buenos Aires se escribe en plural" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen=""></iframe>
          <div class="card-body">
            <h2 class="card-title">Título del destacado</h2>
            <p class="card-text">
              Este es el cuerpo de un destacado. Debe ser breve y conciso, de pocas
              líneas. No puede contener negritas ni enlaces. Este es el cuerpo de un
              destacado. Debe ser breve y conciso, de pocas líneas. No puede
              contener negritas ni enlaces
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>