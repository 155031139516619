import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IHoliday } from 'src/app/core/models/holiday';
import { CatalogService } from 'src/app/core/services/http/catalog.service';
import { DownloadService } from 'src/app/core/services/http/download.service';
import { HolidayService } from 'src/app/core/services/http/holiday.service';
import { PublishmentService } from 'src/app/core/services/http/publishment.service';


@Component({
  selector: 'app-catalog-list',
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.scss']
})
export class CatalogListComponent implements OnInit {
  AllHolidays: IHoliday[];

  constructor(
    private _publishmentService: PublishmentService,
    private _holidayService: HolidayService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _catalogService: CatalogService,
    private _downloadService: DownloadService
  ) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params) => {
      this.getAllHolidays();
    });
  }

  getAllHolidays() {
    this._holidayService.getAll().subscribe((res) => {
      this.AllHolidays = res;
    });
  }

}
