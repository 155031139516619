<!--
    <div
      [formGroup]="form"
      class="collapse navbar-collapse"
      id="navbarNavDropdown"
    >
        <li
          class="nav-item nav-item-border nav-item-oferer"
          *ngIf="isOfferer()"
        >
          <a class="nav-link pointer" (click)="goToPublishmentCreate()"
            >Crear publicación</a
          >
        </li>
        <li class="nav-item dropdow nav-item-border" *ngIf="user">
          <div class="dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ user ? user.nombre + " " + (user.apellido || "") : "" }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a
                class="dropdown-item"
                [routerLink]="['/user/profile']"
                *ngIf="!isAdmin()"
                >Perfil</a
              >
              <a
                class="dropdown-item"
                href="javascript:;"
                (click)="logout()"
                *ngIf="!isAdmin()"
                >Cerrar sesión</a
              >
              <a
                (click)="logoutAdm()"
                class="dropdown-item"
                href="javascript:;"
                *ngIf="isAdmin()"
              >
                Cerrar sesión
              </a>
              <form
                style="display: none"
                *ngIf="isAdmin()"
                class="dropdown-item"
                #logoutAdminForm="ngForm"
                (ngSubmit)="onSubmit(logoutAdminForm, $event)"
                [action]="user?.logoutUrl"
                method="post"
              >
                <input type="hidden" name="authorize" value="0" />
                <input type="hidden" name="logout" value="1" />
                <button
                  #btnAdminLogout
                  style="
                    padding: 0;
                    border: none;
                    background: none;
                    max-height: 20px;
                    font-size: 1rem;
                  "
                  type="submit"
                >
                  Cerrar sesión Test
                </button>
              </form>
            </div>
          </div>
        </li>
    </div> -->
<div
  class="alert alert-danger mt-2 mb-0 rounded-0"
  role="alert"
  *ngIf="showAlert()"
>
  <i class="glyphicon glyphicon-exclamation-sign"></i>
  Detectamos que aún no tienes definido a que tipo de usuario representas. ¡Hace
  click
  <a class="alert-link-banner" [routerLink]="['/user/register']">acá</a> para
  completarlo!
</div>
<div class="storybook__container-header">
  <header class="navbar navbar-light navbar-expand-lg" role="banner">
    <a href="#" class="skip-to-main-content-link">
      Saltar al contenido principal
    </a>
    <div class="container header-container">
      <a [routerLink]="['/home']" class="navbar-brand">
        <img
          class="header-logo"
          src="/assets/images/ba_logo.svg"
          alt="Gobierno de la Ciudad de Buenos Aires - Inicio"
        />
      </a>
      <div class="navbar-login-mobile">
        <a
          class="btn btn-lg btn-icon btn-outline-link"
          href="#"
          target="_blank"
        >
          <span class="material-icons-round">person</span>
          <span class="btn-text">Ingresar a mi BA</span>
        </a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Menú"
      ></button>
      <div class="collapse navbar-collapse" id="navbarContent">
        <div class="navbar-content">
          <div class="navbar-search">
            <form class="form-search">
              <div class="form-group" [formGroup]="form">
                <label class="sr-only" for="search-input">Buscador</label>
                <input
                  type="search"
                  formControlName="search"
                  class="form-control input-search"
                  id="search-input"
                  name="name"
                  placeholder="Buscar en Buenos Aires"
                  (keydown.enter)="goToSearch()"
                />
                <button class="reset" type="reset" aria-label="Borrar"></button>
                <button
                  class="submit-search"
                  type="submit"
                  aria-label="Buscar"
                  (click)="goToSearch()"
                ></button>
              </div>
            </form>
          </div>
          <div class="navbar-login" *ngIf="!user">
            <a
              class="btn btn-lg btn-icon btn-outline-link"
              (click)="goToMiBA()"
            >
              <span class="material-icons-round">person</span>
              <span class="btn-text">Ingresar a mi BA</span>
            </a>
          </div>
        </div>
        <div class="navbar-content-extended">
          <nav>
            <p class="navbar-sections-title">Secciones</p>
            <ul class="nav nav-pills nav-sections">
              <li class="dropdown" [ngClass]="{ show: activeDropdown === 0 }">
                <button
                  type="button"
                  class="btn btn-dropdown btn-dropdown-lg dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  [attr.aria-expanded]="activeDropdown === 0"
                  (click)="toggleDropdown(0)"
                >
                  <span class="btn-dropdown-text">Inicio</span>
                  <span class="material-icons-round btn-dropdown-icon">
                    expand_more
                  </span>
                </button>
                <div
                  class="dropdown-menu"
                  [ngClass]="{ show: activeDropdown === 0 }"
                >
                  <a class="dropdown-item" href="/#valor-ba">
                    <span class="item-text">¿Qué es ValorBA?</span>
                  </a>
                  <a class="dropdown-item" href="/quiero-vender">
                    <span class="item-text">Quiero vender</span>
                  </a>
                  <a class="dropdown-item" href="/quiero-comprar">
                    <span class="item-text">Quiero comprar</span>
                  </a>
                  <a class="dropdown-item" href="/#asesoramiento">
                    <span class="item-text">Quiero asesoramiento</span>
                  </a>
                </div>
              </li>
              <li class="dropdown" [ngClass]="{ show: activeDropdown === 1 }">
                <button
                  type="button"
                  class="btn btn-dropdown btn-dropdown-lg dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  [attr.aria-expanded]="activeDropdown === 1"
                  (click)="toggleDropdown(1)"
                >
                  <span class="btn-dropdown-text">Buscador</span>
                  <span class="material-icons-round btn-dropdown-icon">
                    expand_more
                  </span>
                </button>
                <div
                  class="dropdown-menu"
                  [ngClass]="{ show: activeDropdown === 1 }"
                >
                  <a class="dropdown-item" (click)="navigateToSection(1)">
                    <span class="item-text">Producto</span>
                  </a>
                  <a class="dropdown-item" (click)="navigateToSection(2)">
                    <span class="item-text">Servicio</span>
                  </a>
                  <a class="dropdown-item" (click)="navigateToSection(3)">
                    <span class="item-text">Emprendimiento</span>
                  </a>
                  <a class="dropdown-item" (click)="navigateToSection(4)">
                    <span class="item-text">Todo</span>
                  </a>
                </div>
              </li>
              <li class="dropdown" [ngClass]="{ show: activeDropdown === 3 }">
                <button
                  type="button"
                  class="btn btn-dropdown btn-dropdown-lg dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  [attr.aria-expanded]="activeDropdown === 3"
                  (click)="toggleDropdown(3)"
                >
                  <span class="btn-dropdown-text">Empresas</span>
                  <span class="material-icons-round btn-dropdown-icon">
                    expand_more
                  </span>
                </button>
                <div
                  class="dropdown-menu"
                  [ngClass]="{ show: activeDropdown === 3 }"
                >
                  <a class="dropdown-item" href="/#buscador-home">
                    <span class="item-text">Generá compras</span>
                  </a>
                  <a class="dropdown-item" href="#">
                    <span class="item-text">Empresas aliadas</span>
                  </a>
                  <a
                    class="dropdown-item"
                    href="https://buenosaires.gob.ar/desarrollohumanoyhabitat/potencial-humano/sello-de-impacto-social"
                    target="_blank"
                  >
                    <span class="item-text">Obtené el sello</span>
                  </a>
                </div>
              </li>
              <li class="dropdown" [ngClass]="{ show: activeDropdown === 4 }">
                <button
                  type="button"
                  class="btn btn-dropdown btn-dropdown-lg dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  [attr.aria-expanded]="activeDropdown === 4"
                  (click)="toggleDropdown(4)"
                >
                  <span class="btn-dropdown-text">Testimonios</span>
                  <span class="material-icons-round btn-dropdown-icon">
                    expand_more
                  </span>
                </button>
                <div
                  class="dropdown-menu"
                  [ngClass]="{ show: activeDropdown === 4 }"
                >
                  <a class="dropdown-item" href="/testimonios/#rondas">
                    <span class="item-text">Rondas</span>
                  </a>
                  <a class="dropdown-item" href="/testimonios/#ferias">
                    <span class="item-text">Ferias</span>
                  </a>
                  <a class="dropdown-item" href="/testimonios/#casos-de-exito">
                    <span class="item-text">Casos de éxito</span>
                  </a>
                </div>
              </li>
              <li class="dropdown" [ngClass]="{ show: activeDropdown === 2 }">
                <button
                  type="button"
                  class="btn btn-dropdown btn-dropdown-lg dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  [attr.aria-expanded]="activeDropdown === 2"
                  (click)="toggleDropdown(2)"
                >
                  <span class="btn-dropdown-text">Proveedores</span>
                  <span class="material-icons-round btn-dropdown-icon">
                    expand_more
                  </span>
                </button>
                <div
                  class="dropdown-menu"
                  [ngClass]="{ show: activeDropdown === 2 }"
                >
                  <a
                    class="dropdown-item"
                    href="https://buenosaires.gob.ar/desarrollohumanoyhabitat/potencial-humano/potencia-tu-emprendimiento"
                    target="_blank"
                  >
                    <span class="item-text">Cursos presenciales</span>
                  </a>
                  <a class="dropdown-item" href="#">
                    <span class="item-text">Cursos autogestionados</span>
                  </a>
                  <a
                    class="dropdown-item"
                    href="https://ba.presupuestador.ar/"
                    target="_blank"
                  >
                    <span class="item-text">Presupuestador BA</span>
                  </a>
                  <a
                    class="dropdown-item"
                    href="https://buenosaires.gob.ar/noticias/que-es-valor-popular-y-como-funciona"
                    target="_blank"
                  >
                    <span class="item-text">Sello Valor Popular</span>
                  </a>
                  <a class="dropdown-item" href="#">
                    <span class="item-text">Microcréditos emprendedores</span>
                  </a>
                  <a
                    class="dropdown-item"
                    href="https://buenosaires.gob.ar/desarrollohumanoyhabitat/potencial-humano/fondes"
                    target="_blank"
                  >
                    <span class="item-text">FONDES cooperativas</span>
                  </a>
                </div>
              </li>
              <li class="dropdown" [ngClass]="{ show: activeDropdown === 5 }">
                <button
                  type="button"
                  class="btn btn-dropdown btn-dropdown-lg dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  [attr.aria-expanded]="activeDropdown === 5"
                  (click)="toggleDropdown(5)"
                >
                  <span class="btn-dropdown-text">Novedades</span>
                  <span class="material-icons-round btn-dropdown-icon">
                    expand_more
                  </span>
                </button>
                <div
                  class="dropdown-menu"
                  [ngClass]="{ show: activeDropdown === 5 }"
                >
                  <a class="dropdown-item" href="#">
                    <span class="item-text">Newsletters</span>
                  </a>
                  <a class="dropdown-item" href="#">
                    <span class="item-text">Eventos</span>
                  </a>
                  <a class="dropdown-item" href="#">
                    <span class="item-text">Actividades</span>
                  </a>
                  <a class="dropdown-item" [routerLink]="['/catalog']">
                    <span class="item-text">Campañas</span>
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="header-backdrop"></div>
  </header>
</div>
