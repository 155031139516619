import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() menuItems: { path: string; url: string }[];

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.menuItems = this.activatedRoute.snapshot.data['breadcrumb'];
  }
}
