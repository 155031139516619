import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/config/app-config.service';

@Component({
  selector: 'app-comprar',
  templateUrl: './quiero-comprar.component.html',
  styleUrls: ['./quiero-comprar.component.scss'],
})
export class ComprarComponent {
  protected settings = AppConfigService.settings;
  constructor(private router: Router) { }

  obtenerUrlWhatsapp() {
    const mensaje: string =
      '¡Hola! Estoy interesado en recibir asesoramiento personalizado sobre cómo comprar con impacto en ValorBA.';

    const urlEncodedMessage = encodeURIComponent(mensaje);
    return `https://wa.me/${this.settings.whatsapp}?text=${urlEncodedMessage}`;
  }
}
