<div class="relative">
  <form
    [formGroup]="searchForm"
    (keydown.enter)="($event.target.name != 'location')"
    (ngSubmit)="currentPage = 0; onSubmit()"
    novalidate
    *ngIf="searchForm"
    autocomplete="off"
  >
    <div class="row mt-4">
      <div class="col-lg-2 col-md-6">
        <div class="select-wrapper">
          <select
            class="form-control"
            placeholder="Buscar por"
            formControlName="type"
            (change)="enableControls()"
            aria-label="Elige una categoría de busqueda"
          >
            <option value="">Buscar por</option>
            <option
              *ngFor="let type of dataSearch.tiposBusqueda"
              value="{{ type.id }}"
            >
              {{
                type.nombre == "Emprendedor" ? "Emprendimiento" : type.nombre
              }}
            </option>
            <option value="all">Todo</option>
          </select>
        </div>
      </div>

      <div class="col-lg-2 col-md-6">
        <div class="select-wrapper">
          <select
            class="form-control"
            placeholder="Rubro"
            formControlName="rubric"
            aria-label="Elige un Rubro"
          >
            <option value="">Rubro</option>
            <option
              *ngFor="let rubric of rubrics"
              value="{{ rubric.id }}"
              title="{{ rubric.nombre }}"
            >
              {{
                rubric.nombre.length > 40
                  ? rubric.nombre.slice(0, 40) + "..."
                  : rubric.nombre
              }}
            </option>
          </select>
        </div>
      </div>

      <div
        [ngClass]="{
          'col-lg-3 col-md-6':
            searchForm.controls.type.value !==
            enumPublishmentTypes.Perfil.toString(),
          'col-lg-6 col-md-12':
            searchForm.controls.type.value ===
            enumPublishmentTypes.Perfil.toString()
        }"
      >
        <div class="input-group">
          <input
            type="text"
            class="form-control pr-1"
            placeholder="¿Qué estás buscando?"
            maxlength="101"
            formControlName="query"
            aria-label="¿Qué estás buscando?"
          />
          <span
            class="glyphicon glyphicon-search form-control-icon"
            *ngIf="!searchForm.controls.query.value"
          ></span>
        </div>
        <div
          role="alert"
          tabindex="0"
          *ngIf="
            searchForm.controls.query?.dirty &&
            searchForm.controls.query?.errors?.minlength
          "
        >
          <span class="sr-only">Error: </span>
          <span class="text-danger"> Ingrese al menos 3 caracteres </span>
        </div>
        <div
          role="alert"
          tabindex="0"
          *ngIf="
            searchForm.controls.query?.dirty &&
            searchForm.controls.query?.errors?.maxlength
          "
        >
          <span class="sr-only">Error: </span>
          <span class="text-danger">
            La cantidad máxima de caracteres es de 100
          </span>
        </div>
      </div>

      <div
        class="col-lg-3 col-md-6"
        *ngIf="
          searchForm.controls.type.value !==
          enumPublishmentTypes.Perfil.toString()
        "
      >
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="¿Dónde?"
            formControlName="location"
            name="location"
            maxlength="19"
            (keyup)="searchLocation($event)"
            (focus)="listLocations()"
            (blur)="hideLocations()"
            #input
            aria-label="En que barrio se encuentra?"
            (keydown)="onKeydown($event)"
          />
          <span
            class="glyphicon glyphicon-map-marker form-control-icon"
            *ngIf="!searchForm.controls.location.value"
          ></span>
        </div>
        <section class="list-group" #section>
          <app-list-locations
            *ngFor="
              let location of locationResults
                | locationFilter : 'nombre' : input.value;
              index as index
            "
            [location]="location"
            id="location_{{ index }}"
            attr.aria-label="{{ location.nombre }}"
            class="list-group-item"
            (mousedown)="selectLocation(location)"
          >
            {{ location.nombre }}
          </app-list-locations>
        </section>
        <div
          role="alert"
          tabindex="0"
          *ngIf="
            searchForm.controls.location.invalid &&
            searchForm.controls.location.value != ''
          "
        >
          <span class="sr-only">Error: </span>
          <span class="text-danger"> El barrio es incorrecto </span>
        </div>
      </div>
      <div class="checkbox-group row row-categorias">
        <div class="col-md-12">
          <div
            class="container-categories"
            *ngIf="rubricSelected && checkboxOptions.length > 0"
          >
            <h5>Categorías</h5>
            <div class="check-container" *ngIf="!categorySelected">
              <label
                *ngFor="let option of checkboxOptions; let i = index"
                class="option"
                [for]="'checkbox_' + option.id"
                (click)="onCheckChange(option.id, option)"
              >
                {{ option.nombre }}
              </label>
            </div>
            <div class="check-container" *ngIf="categorySelected">
              <label
                (click)="onCheckChange(actualCategory.id, actualCategory)"
                class="option"
                >{{ actualCategory.nombre }}
                <i class="glyphicon glyphicon-remove ml-1"></i>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-md-6">
        <!-- <div
        class="last-error mb-2"
        role="alert"
        tabindex="0"
        *ngIf="
          searchForm.controls.type.value && !searchForm.controls.query.value
        "
      >
        <span class="sr-only">Error: </span>
        <span class="text-danger">
          Seleccione un rubro o ingrese el texto que desea buscar
        </span>
      </div> -->
        <div class="input-group">
          <button
            type="submit"
            #submit
            class="btn btn-block button-primary"
            [ngClass]="searchForm.invalid ? 'disabled' : 'enabled'"
          >
            Buscar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
