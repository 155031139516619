import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './modules/home/home.module';
import { SearchModule } from './modules/search/search.module';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { HttpRequestInterceptor } from './core/interceptors/http-request.interceptor';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { HttpErrorInterceptor } from './core/interceptors/http-errors.interceptor';
import { APP_BASE_HREF } from '@angular/common';
import { AppConfigService } from './core/config/app-config.service';
import * as ConfigJson from '../assets/json/config.json';
import { NgxPaginationModule } from 'ngx-pagination';
import { CatalogComponent } from './modules/catalog/catalog.component';
import { CatalogListComponent } from './modules/catalog-list/catalog-list.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TestimoniosModule } from './modules/testimonios/testimonios.module';
import { VenderModule } from './modules/quiero-vender/quiero-vender.module';
import { ComprarModule } from './modules/quiero-comprar/quiero-comprar.module';

export function initializeApp(appConfigService: AppConfigService) {
  return (): Promise<any> => {
    return appConfigService.load();
  };
}

@NgModule({
  declarations: [AppComponent, CatalogComponent, CatalogListComponent],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    HomeModule,
    SearchModule,
    TestimoniosModule,
    VenderModule,
    ComprarModule,
    AppRoutingModule,
    NgxPaginationModule,
    NgbTooltipModule,
    ReCaptchaModule.forRoot(ConfigJson.RECAPTCHA_OPTION),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
