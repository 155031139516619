<app-breadcrumb></app-breadcrumb>
<div class="container justify-content-center pb-5 pt-5" role="main">
  <h1 class="text-center">Quiero vender</h1>
  <div class="row align-items-center d-flex justify-content-around">
    <div class="yt-video col-md-5"></div>
    <div class="yt-video col-md-5"></div>
  </div>
</div>
<div class="row sec-anunciarme">
  <div class="col-md-12 text-center">
    <button class="btn button-primary" (click)="goToMiBA()">
      Creá tu perfil
    </button>
  </div>
</div>
<div class="container d-flex flex-column align-items-center">
  <div class="pb-5">
    <h1 class="pb-3 pt-5">Tips para vender en valorBA</h1>
    <ul class="list-steps list-steps-sm">
      <li class="list-steps-item">
        <h3>Creá tu perfil en valorBA</h3>
      </li>
      <li class="list-steps-item">
        <h3>Contá a qué se dedica tu emprendimiento y cuál es su historia</h3>
      </li>
      <li class="list-steps-item">
        <h3>Armá tus presupuestos</h3>
      </li>
      <li class="list-steps-item">
        <h3>Prepará tu pedido</h3>
      </li>
      <li class="list-steps-item">
        <h3>Realizá la factura de tu venta</h3>
      </li>
    </ul>
  </div>
  <a [href]="obtenerUrlWhatsapp()" target="_blank" rel="noopener noreferrer"
    class="btn btn-primary btn-lg btn-asesoramiento mb-5">
    Quiero asesoramiento
  </a>
</div>
<app-help-button></app-help-button>