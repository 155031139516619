// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IAppConfig } from "src/app/core/config/app-config";

export const environment: IAppConfig = {
  url_server: 'https://valorba-api.1961.com.ar/api',
  env: "production",
  miba_url: "https://mibalogin-html.gcba.gob.ar/auth/realms/miba/account/",
  whatsapp: "5491128367974",
  production: false,
  show_imgs: true,
  RECAPTCHA_OPTION: {
    language: "es",
    invisible: {
      sitekey: "6Lc8tT8bAAAAAKVLX0GbpFj0WCQC__6chF7g_ktO"
    },
    normal: {
      sitekey: "6Lc8tT8bAAAAAKVLX0GbpFj0WCQC__6chF7g_ktO"
    }
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
