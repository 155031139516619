<app-slider></app-slider>
<div *ngIf="!user" class="row sec-anunciarme">
  <div class="col-md-12 text-center">
    Quiero formar parte del catálogo
    <button class="btn button-primary" (click)="goToPublishmentCreate()">
      Anunciarme
    </button>
  </div>
</div>
<div class="container justify-content-center pb-5 pt-5" id="buscador-home">
  <h1 class="text-center">¿Qué estás buscando?</h1>
  <p class="lead text-center">
    Ingresá el producto o servicio que necesitas, o el nombre de un
    emprendimiento específico para ver los resultados. Asegúrate de
    <span class="bold">seleccionar una categoría de búsqueda</span> de la lista
    antes de presionar <span class="bold">buscar</span>.
  </p>
  <app-search-form> </app-search-form>
</div>

<div class="container justify-content-center pb-5 pt-5" id="valor-ba">
  <h2 class="text-center">¿Qué es ValorBA?</h2>
  <p class="lead text-center">
    ValorBA es un catálogo digital que conecta productos y servicios de
    proveedores de impacto con empresas, Estado vecinos/as de la ciudad.
  </p>
  <p class="lead text-center pb-3">
    Conocé toda la oferta y generá compras con ValorBA
  </p>
  <div class="panel-footer d-flex justify-content-center">
    <a class="btn btn-primary btn-lg mr-3" (click)="goToBuy()"
      >Quiero comprar</a
    >
    <a class="btn btn-secondary btn-lg" (click)="goToSell()">Quiero vender</a>
  </div>
</div>

<!-- <div
  class="container-fluid justify-content-center mb-5 mt-3 pt-4 pb-5 home-slider"
>
  <div class="container">
    <h2 class="text-center">Rubros</h2>
    <h3 class="mt-4 mb-3 pl-2">Productos</h3>
    <app-slider-cards
      [items]="getRubricsForType(enumPublishmentTypes.Producto)"
      [itemsForGroup]="itemsForGroup"
    >
    </app-slider-cards>
    <h3 class="mt-3 mb-3 pl-2">Servicios</h3>
    <app-slider-cards
      [items]="getRubricsForType(enumPublishmentTypes.Servicio)"
      [itemsForGroup]="itemsForGroup"
    >
    </app-slider-cards>
  </div>
</div> -->

<div class="container-fluid justify-content-center mt-3 pt-5 pb-5 home-slider">
  <div class="container">
    <h2 class="text-center mb-3">
      ¿Qué impactos generan las compras de ValorBA?
    </h2>
    <div class="row">
      <div class="col-md-3 text-center">
        <span class="material-icons-round icon pb-3"> diversity_1 </span>
        <p class="lead bold text-center">Impacto Social</p>
        <p class="status-text text-center">
          Con tu compra mejorás la economía de proveedores en situación de
          vulnerabilidad social y económica.
        </p>
      </div>
      <div class="col-md-3 text-center">
        <span class="material-icons-round icon pb-3"> female </span>
        <span class="material-icons-round icon pb-3 male"> male </span>
        <p class="lead bold text-center">Impacto de Género</p>
        <p class="status-text text-center">
          Con tu compra das oportunidades a proveedores integrados en su mayoría
          por mujeres y disidencias.
        </p>
      </div>
      <div class="col-md-3 text-center">
        <span class="material-icons-round icon pb-3"> public </span>
        <p class="lead bold text-center">Impacto Ambiental</p>
        <p class="status-text text-center">
          Con tu compra colaborás con proveedores que promueven el cuidado del
          planeta a través del reciclaje y el compostaje, la reducción del
          consumo de productos de un solo uso y el fomento a la economía
          circular .
        </p>
      </div>
      <div class="col-md-3 text-center">
        <span class="material-icons-round icon pb-3"> paid </span>
        <p class="lead bold text-center">Impacto Económico</p>
        <p class="status-text text-center">
          Con tu compra impulsás la economía de pequeños y medianos proveedores
          para que sigan creciendo.
        </p>
      </div>
    </div>
  </div>
</div>
<app-help-button></app-help-button>
