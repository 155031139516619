<app-breadcrumb></app-breadcrumb>
<div class="container">

  <div class="row">
    <div class="col-md-12 pt-5 text-center">
      <h1>Campañas</h1>
      <h2>Descripción para las campañas</h2>
    </div>
  </div>
  <div *ngIf="AllHolidays" class="row pb-3">
    <div class="panel-horizontal-content" *ngFor="let item of AllHolidays">
      <div class="card card-simple panel-horizontal">
        <img [src]="item.imagen.url" [alt]="item.title" class="card-img" />
        <div class="card-body">
          <h2 class="card-title">{{ item.title }}</h2>
          <p class="card-text">
            {{ item.description }}
          </p>
          <div class="panel-footer">
            <a class="btn btn-primary btn-lg" [href]="'/catalog/'+item.id">Ver más</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>