import { Component, OnInit } from '@angular/core';
import { IPublishmentType } from 'src/app/core/models/publishment-type';
import { IRubric } from 'src/app/core/models/rubric';
import { RubricService } from 'src/app/core/services/http/rubric.service';
import { EPublishmentTypes } from 'src/app/core/enums/publishment-type';
import { User } from 'src/app/core/models/user';
import { StoreService } from 'src/app/core/services/store.service';
import { Router } from '@angular/router';
import { environment as env } from 'src/environments/environment';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { isAdmin, isOfferer } from 'src/app/shared/functions/helper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  protected apiServer = AppConfigService.settings.url_server;
  rubrics: IRubric[];
  publishmentTypes: IPublishmentType[];
  itemsForGroup: number;
  enumPublishmentTypes = EPublishmentTypes;
  user: User;

  constructor(
    private rubricService: RubricService,
    private storeService: StoreService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getRubrics();
    this.cleanStores();
    this.setItemsForGroup();
    this.storeService.watchStorage().subscribe((data: string) => {
      this.user = JSON.parse(localStorage.getItem('user'));
    });
    const user = localStorage.getItem('user');
    if (user) {
      this.storeService.emitChanges();
    }
  }

  isAdmin() {
    return isAdmin(this.user);
  }

  isOfferer() {
    return isOfferer(this.user);
  }

  getRubrics() {
    this.rubricService.getAll().subscribe((res) => {
      this.rubrics = res;
    });
  }

  getRubricsForType(type) {
    return this.rubrics?.filter((rubric) => {
      return rubric.tipoPublicacion_id === type;
    });
  }

  cleanStores() {
    localStorage.removeItem('publishmentId');
    localStorage.removeItem('searchFilters');
  }

  goToPublishmentCreate() {
    if (this.user) {
      if (this.user.registroCompleto) {
        if (this.user.perfilPublicacion_id) {
          this.router.navigate(['/publishment/create']);
        } else {
          this.router.navigate(['/publishment/new-profile']);
        }
      } else {
        this.router.navigate(['/user/register']);
      }
    } else {
      window.location.href =
        this.apiServer + '/auth/redirect?url=' + window.location.href;
      //window.location.href.replace('#', '%23');
    }
  }

  goToSell() {
    this.router.navigate(['/quiero-vender']);
  }

  goToBuy() {
    this.router.navigate(['/quiero-comprar']);
  }

  private setItemsForGroup() {
    const isMobile = window.matchMedia(
      'only screen and (max-width: 760px)'
    ).matches;
    if (isMobile) {
      this.itemsForGroup = 1;
    } else {
      const isTablet = window.matchMedia(
        'only screen and (min-width: 768px) and (max-width: 1023px)'
      ).matches;
      this.itemsForGroup = isTablet ? 2 : 4;
    }
  }
}
